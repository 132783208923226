import React from "react";

function ContactUs() {
	return (
		<section>
			<h1>ContactUs</h1>
		</section>
	);
}

export default ContactUs;
